import type { MetaFunction } from "@remix-run/node";
import AboutSection from "~/components/AboutSection";
import Footer from "~/components/Footer";
import HeroSection from "~/components/HeroSection";
import NewsletterSection from "~/components/NewsletterSection";
import SolutionsSection from "~/components/SolutionsSection";
import TestimonialsSection from "~/components/TestimonialsSection";

export const meta: MetaFunction = () => {
  return [
    { title: "MaharooLab - AI Solutions for Educators" },
    {
      name: "description",
      content:
        "Empowering educators with AI-driven tools for modern classrooms.",
    },
  ];
};

export default function Index() {
  return (
    <div className="w-full overflow-x-hidden">
      <HeroSection />
      <AboutSection />
      <SolutionsSection />
      <TestimonialsSection />
      <NewsletterSection />
      <Footer />
    </div>
  );
}
