import { Link } from "react-scroll";

export default function Footer() {
  return (
    <footer className="flex flex-col items-center justify-center py-8 border-t border-gray-200 bg-white text-center">
      <div className="flex gap-8 mb-4">
        <Link
          to="hero"
          smooth={true}
          duration={500}
          spy={true}
          hashSpy={true}
          className="text-lg text-gray-600 hover:text-blue-600 cursor-pointer"
        >
          Home
        </Link>
        <Link
          to="about-us"
          smooth={true}
          duration={500}
          spy={true}
          hashSpy={true}
          className="text-lg text-gray-600 hover:text-blue-600 cursor-pointer"
        >
          About
        </Link>
        <Link
          to="solutions"
          smooth={true}
          duration={500}
          spy={true}
          hashSpy={true}
          className="text-lg text-gray-600 hover:text-blue-600 cursor-pointer"
        >
          Solutions
        </Link>
      </div>
      <hr className="w-1/2 border-gray-200 my-4" />
      <p className="text-gray-600 text-base">
        © {new Date().getFullYear()} MaharooLab. All rights reserved.
      </p>
      <p className="text-gray-600 text-sm mt-2">
        Illustrations by{" "}
        <a
          href="https://undraw.co/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-blue-600"
        >
          unDraw
        </a>
      </p>
    </footer>
  );
}
