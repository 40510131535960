import { motion } from "framer-motion";
import { Link } from "react-scroll";

export default function HeroSection() {
  return (
    <section
      id="hero"
      className="relative flex flex-col items-center justify-center min-h-screen bg-primary text-center text-default p-20 overflow-hidden"
    >
      {/* Background Image */}
      <img
        src="/online-learning.svg" // Ensure this is in the public folder, or use a valid URL
        alt="AI and education illustration"
        className="absolute inset-0 w-full h-full object-cover opacity-20" // Adjust opacity if needed
      />

      {/* Hero Content */}
      <motion.h1
        className="text-6xl font-extrabold mb-6 relative"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Empowering Educators with AI-Driven Tools
      </motion.h1>
      <p className="text-2xl max-w-2xl mx-auto mb-12 text-default/90 relative">
        At MaharooLab, we’re on a mission to enhance education with AI solutions
        tailored for the classroom.
      </p>
      <Link
        to="about-us" // ID of the target section to scroll to
        smooth={true}
        duration={1000}
        offset={-70} // Adjust for header height or any offset
        spy={true}
        hashSpy={true}
      >
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="px-10 py-4 bg-default text-primary font-semibold rounded-lg text-lg shadow-lg hover:bg-default/90 transition relative"
        >
          Get To Know Us
        </motion.button>
      </Link>
    </section>
  );
}
