import { motion } from "framer-motion";
import { Link } from "react-scroll";

export default function AboutSection() {
  return (
    <section
      id="about-us"
      className="flex flex-col md:flex-row items-center justify-center min-h-screen bg-white py-16 gap-12 text-center md:text-left bg-default p-4"
    >
      {/* Image Section with Motion Animation */}
      <motion.div
        className="w-full md:w-[500px] h-[350px] bg-gray-300 rounded-lg shadow-lg overflow-hidden"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        <img
          src="/reading.svg" // Change to your preferred Undraw image
          alt="About MaharooLab"
          className="w-full h-full object-cover"
          loading="lazy"
        />
      </motion.div>

      {/* Text Content Section */}
      <div className="max-w-2xl">
        <h2 className="text-5xl font-semibold text-primary mb-6">
          About MaharooLab
        </h2>
        <p className="text-xl text-gray-700 mb-6">
          MaharooLab was founded by educators who understand the challenges of
          today’s classrooms. Our goal is to leverage AI to create tools that
          make teaching impactful and efficient.
        </p>
        <p className="text-xl text-gray-700 mb-6">
          We focus on practical solutions that support teachers’ daily
          activities, helping them better connect with students and enhance
          learning outcomes.
        </p>

        {/* Button to Solutions Section */}
        <Link
          to="solutions"
          smooth={true}
          duration={1000}
          offset={-70} // Adjust this if needed
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="px-10 py-4 bg-primary text-white font-semibold rounded-lg text-lg shadow-lg hover:bg-primary/80 transition"
          >
            Explore Our Solutions
          </motion.button>
        </Link>
      </div>
    </section>
  );
}
