import { motion } from "framer-motion";
import { useState } from "react";

export default function NewsletterSection() {
  const [email, setEmail] = useState("");
  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubscribe = async () => {
    setIsSubmitting(true);
    setStatusMessage(null);

    try {
      const response = await fetch("/api/subscribe", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (data.success) {
        setStatusMessage("Thank you for subscribing!");
      } else {
        setStatusMessage(data.error || "An error occurred. Please try again.");
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setStatusMessage(
          error.message || "An error occurred. Please try again."
        );
      } else {
        setStatusMessage("An error occurred. Please try again.");
      }
    } finally {
      setIsSubmitting(false);
      setEmail("");
    }
  };

  return (
    <section
      id="newsletter"
      className="flex flex-col items-center justify-center min-h-screen bg-secondary text-center text-white p-4 py-16"
    >
      <h2 className="text-5xl font-semibold mb-6">Stay Connected</h2>
      <p className="text-2xl mb-8 max-w-3xl">
        Sign up for our newsletter to stay updated on the latest in AI for
        education, and access exclusive resources.
      </p>

      <div className="mt-10 flex flex-col sm:flex-row gap-4 max-w-lg w-full">
        <input
          type="email"
          placeholder="Your email address"
          className="flex-grow px-6 py-4 border border-gray-300 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-primary text-primary"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled={isSubmitting}
        />
        <motion.button
          onClick={handleSubscribe}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="px-10 py-4 bg-primary text-default rounded-lg text-lg shadow-lg hover:bg-primary/90 transition"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Signing Up..." : "Sign Up"}
        </motion.button>
      </div>

      {/* Toast Notification */}
      {statusMessage && (
        <p
          className={`mt-4 text-lg ${
            statusMessage.startsWith("Thank")
              ? "text-green-200"
              : "text-red-500"
          }`}
        >
          {statusMessage}
        </p>
      )}

      {/* Contact Information */}
      <div className="p-6 mt-8 max-w-xl w-full">
        <p className="text-xl font-medium">
          Prefer to reach out directly? Contact us anytime at:
        </p>
        <a
          href="mailto:info@maharoolab.com"
          className="block mt-2 text-2xl font-semibold hover:text-white/80 transition"
        >
          info@maharoolab.com
        </a>
      </div>
    </section>
  );
}
