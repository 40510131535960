import { motion } from "framer-motion";

const testimonials = [
  {
    name: "Alex T.",
    feedback:
      "Assessain.com has been a useful tool in our school. It simplifies the process of creating and evaluating worksheets, making things more manageable for both teachers and students.",
  },
  {
    name: "Taylor R.",
    feedback:
      "Using Assessain.com has helped us streamline our assessment process. It's easy to create quizzes and track results, though it could benefit from some additional customization options.",
  },
  {
    name: "Petr N.",
    feedback:
      "Assessain.com has helped in simplifying worksheet creation, especially with the ability to auto-evaluate answers. While it's an improvement, I believe there are areas for further development to better suit the local educational system.",
  },
  {
    name: "Jana K.",
    feedback:
      "As a teacher, Assessain.com has proven practical for creating and grading worksheets quickly. It offers a solid starting point, though I would like to see more integration with other educational tools we use.",
  },
  {
    name: "Marek D.",
    feedback:
      "The integration of AI tools like Assessain.com into the classroom is an interesting approach to improving the efficiency of education. It’s a step in the right direction, but we are carefully monitoring its impact and seeking further innovations in the sector.",
  },
];

export default function TestimonialsSection() {
  return (
    <section
      id="testimonials"
      className="flex flex-col items-center justify-center min-h-screen bg-white text-center py-16"
    >
      <h2 className="text-5xl font-semibold text-primary mb-12">
        What Educators Say
      </h2>
      <div className="grid gap-12 grid-cols-1 md:grid-cols-2 max-w-5xl">
        {testimonials.map((testimonial, index) => (
          <motion.div
            key={index}
            className="p-10 bg-gray-100 rounded-xl shadow-lg"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <p className="text-2xl italic text-gray-700 mb-6">
              {'"'}
              {testimonial.feedback}
              {'"'}
            </p>
            <p className="text-xl font-bold text-gray-800">
              - {testimonial.name}
            </p>
          </motion.div>
        ))}
      </div>
    </section>
  );
}
