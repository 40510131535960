import { motion } from "framer-motion";

const solutions = [
  {
    title: "Assessain.com",
    description:
      "Assessain is an online app that helps educators create customizable worksheets. It offers features like QR code submissions, autoevaluation, and support for both open and multiple-choice questions.",
    image: "/assessain.webp", // Placeholder for screenshot of app
    link: "https://www.assessain.com", // URL to the app
    linkText: "Visit Assessain",
  },
  {
    title: "Tailored Solutions for Schools",
    description:
      "We provide customized solutions for schools, focusing on their specific needs. Whether it's through the integration of AI, advanced technology, or educator training, we help institutions modernize and improve their learning environments.",
    image: "/educator.svg", // Image from Undraw
  },
  {
    title: "Consultations for Governments",
    description:
      "We offer consultations for governments in the fields of education and technology. From developing digital education strategies to implementing cutting-edge tech in classrooms, we support policy-makers to create sustainable, future-ready education systems.",
    image: "/connected-world.svg", // Image from Undraw
  },
];

export default function SolutionsSection() {
  return (
    <section
      id="solutions"
      className="flex flex-col items-center justify-center min-h-screen bg-secondary text-center py-16 relative"
    >
      <h2 className="text-5xl font-semibold text-white mb-12">Our Solutions</h2>

      {/* Solution Cards */}
      <div className="w-full max-w-5xl">
        {solutions.map((solution, index) => (
          <motion.div
            key={index}
            className="p-10 bg-primary rounded-xl shadow-lg transform hover:scale-105 transition mb-12 text-white"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <div className="flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-10">
              {/* Screenshot Section */}
              <div className="w-full md:w-1/2">
                <img
                  src={solution.image}
                  alt="Assessain app screenshot"
                  className="w-full h-auto rounded-lg shadow-md bg-white"
                  loading="lazy"
                />
              </div>

              {/* Text Section */}
              <div className="w-full md:w-1/2 text-center md:text-left">
                <h3 className="text-3xl font-semibold text-white mb-6">
                  {solution.title}
                </h3>
                <p className="text-xl text-white mb-6">
                  {solution.description}
                </p>

                {solution.link && (
                  <motion.a
                    href={solution.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block px-10 py-4 bg-secondary text-white font-semibold rounded-lg text-lg shadow-lg hover:bg-secondary/90 transition"
                  >
                    {solution.linkText || "Learn More"}
                  </motion.a>
                )}
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
}
